import { EncryptStorage } from "encrypt-storage";

// Initialize the EncryptStorage instance with a secret key
const secretKey = process.env.REACT_APP_SECRET_KEY; // Store securely in production
const encryptStorage = new EncryptStorage(secretKey, {
  prefix: "@myapp_",
  storageType: "localStorage",
});

// Function to save encrypted data to local storage
export const saveToLocalStorage = (key, data) => {
  try {
    encryptStorage.setItem(key, data);
  } catch (error) {
    console.error("Error saving to localStorage", error);
  }
};

// Function to retrieve decrypted data from local storage
export const getFromLocalStorage = (key) => {
  try {
    return encryptStorage.getItem(key);
  } catch (error) {
    console.error("Error retrieving from localStorage", error);
    return null;
  }
};

// Function to remove data from local storage
export const removeFromLocalStorage = (key) => {
  try {
    encryptStorage.removeItem(key);
  } catch (error) {
    console.error("Error removing from localStorage", error);
  }
};
