import { useDispatch, useSelector } from 'react-redux'
import { displayNavSidebar } from '../../../redux/slices/showModalSlice'
import { useTranslation } from 'react-i18next'

import './InfoSection.css'
import LocationPin from '../../../assets/footer/location-pin.svg'
import Telephone from '../../../assets/footer/phone-call.svg'
import Email from '../../../assets/footer/email.svg'

import CustomList from './custom-list/CustomList'
import { getFooter } from '../../../redux/slices/footerSlice'
import { useEffect } from 'react'

function InfoSection({ inSidebar = false }) {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const { footerData, status } = useSelector(state => state.footer)

  useEffect(() => {
    if (status === 'idle') {
      dispatch(getFooter())
    }
  }, [status, dispatch])

  const hideNavSidebar = () => {
    dispatch(displayNavSidebar(false))
  }

  const branchesItems = footerData?.our_branches
    ? footerData?.our_branches?.slice(0, 2)?.map(el => {
        return { iconSrc: LocationPin, href: '', text: el.location }
      })
    : []

  const contactItems = [
    {
      iconSrc: Telephone,
      href: '',
      text: footerData?.contact_information?.map(el => el?.email)
    },
    {
      iconSrc: Email,
      href: '',
      text: footerData?.contact_information?.map(el => el?.phone_number)
    }
  ]

  const socialItems = footerData?.follow_us
    ? footerData?.follow_us?.map(el => {
        return {
          iconSrc: `${process.env.REACT_APP_IMAGE_URL}${el?.icon}`,
          href: el?.url
        }
      })
    : []

  return (
    <div className={`custom-info-div ${inSidebar ? 'custom-info-div-sidebar' : ''}`}>
      {inSidebar ? null : <CustomList title='Our Branches' items={branchesItems} />}
      <CustomList title='Contact Information' items={contactItems} inSidebar={inSidebar} />
      <CustomList title='Follow Us' items={socialItems} inSidebar={inSidebar} inRow />
      {inSidebar ? (
        <a
          href=''
          target='_blank'
          rel='noopener noreferrer'
          className='custom-span custom-anchor-tag custom-anchor-tag-sidebar'
          style={inSidebar ? { textAlign: 'center' } : null}
          onClick={hideNavSidebar}
        >
          {t('Powered By')} Gharib Group OY
        </a>
      ) : null}
    </div>
  )
}

export default InfoSection
