import { NavLink, Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { displayNavSidebar } from "../../../redux/slices/showModalSlice";
import { useTranslation } from "react-i18next";

import "./LegalInfoSection.css";

function LegalInfoSection({ inSidebar = false }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const hideNavSidebar = () => {
    dispatch(displayNavSidebar(false));
  };

  return (
    <div
      className={
        inSidebar
          ? "custom-legal-info-container-sidebar"
          : "custom-legal-info-container"
      }
    >
      {inSidebar ? (
        <>
          <span className="custom-legal-info-sidebar-header">
            {t("Information")}
          </span>
          <NavLink
            className="custom-legal-info-sidebar-link"
            to="/about-us"
            onClick={hideNavSidebar}
          >
            {t("About Us")}
          </NavLink>
          <NavLink
            className="custom-legal-info-sidebar-link"
            to="/contact-us"
            onClick={hideNavSidebar}
          >
            {t("Contact Us")}
          </NavLink>
          <NavLink
            className="custom-legal-info-sidebar-link"
            to="/privacy-policy"
            onClick={hideNavSidebar}
          >
            {t("Privacy Policy")}
          </NavLink>
          <NavLink
            className="custom-legal-info-sidebar-link"
            to="/terms-conditions"
            onClick={hideNavSidebar}
          >
            {t("Terms & Conditions")}
          </NavLink>
        </>
      ) : (
        <>
          <span
            className="custom-span"
            style={inSidebar ? { textAlign: "center" } : null}
          >
            {new Date().getFullYear()} {t("All Rights Reserved")}
          </span>
          <a
            href=""
            target="_blank"
            rel="noopener noreferrer"
            className="custom-span custom-anchor-tag"
            style={inSidebar ? { textAlign: "center" } : null}
          >
            {t("Powered By")} Gharib Group OY
          </a>
          <div
            className={
              inSidebar
                ? "custom-policy-container-sidebar"
                : "custom-policy-container"
            }
          >
            <Link
              to="/terms-conditions"
              className="custom-span custom-anchor-tag"
              style={inSidebar ? { textAlign: "center" } : null}
            >
              {t("Terms & Conditions")}
            </Link>
            <Link
              to="/privacy-policy"
              className="custom-span custom-anchor-tag"
              style={inSidebar ? { textAlign: "center" } : null}
            >
              {t("Privacy Policy")}
            </Link>
          </div>
        </>
      )}
    </div>
  );
}

export default LegalInfoSection;
