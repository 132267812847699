import { useEffect, lazy, Suspense } from "react";
import { Routes, Route } from "react-router-dom";
// import { onMessageListener, requestForToken } from "../utils/firebase";
// import { toast } from "react-toastify";
// import { Spinner } from "react-bootstrap";
import "../pages/home-page/order/order-methods-modal/restaurants-list/RestaurantsList.css";
import PrePageLoader from "../components/loaders/prepage-loader/PrePageLoader";
import { useDispatch, useSelector } from "react-redux";
import { getNotifications } from "../redux/slices/notificationsSlice";

const HomePage = lazy(() => import("../pages/home-page/HomePage"));
const OrderPage = lazy(() => import("../pages/order-page/OrderPage"));
const LoginPage = lazy(() => import("../pages/auth-page/login-page/LoginPage"));
const RegisterPage = lazy(() =>
  import("../pages/auth-page/register-page/RegisterPage")
);
const ForgetPassword = lazy(() =>
  import("../pages/auth-page/forget-password/ForgetPassword")
);
const RestaurantPage = lazy(() =>
  import("../pages/restaurant-page/RestaurantPage")
);

const OurBranches = lazy(() => import("../pages/our-branches/OurBranches"));
const ProfilePage = lazy(() => import("../pages/profile-page/ProfilePage"));
const NotificationsPage = lazy(() =>
  import("../pages/notifications-page/NotificationsPage")
);
const MyOrdersPage = lazy(() => import("../pages/myorders-page/MyOrdersPage"));
const CheckOtpCodePage = lazy(() =>
  import("../pages/auth-page/otp-process/CheckOtpCode")
);
const ResetPasswordByPhone = lazy(() =>
  import("../pages/auth-page/reset-password-by-phone/ResetPasswordByPhone")
);
const OrdersPage = lazy(() => import("../pages/myorders-page/OrdersPage"));
const SettingsPage = lazy(() => import("../pages/settings-page/SettingsPage"));
const TermsConditions = lazy(() =>
  import("../pages/settings-page/privacy-policy/TermsConditions")
);

function SiteRoutes() {
  const dispatch = useDispatch();
  const { notificationsStatus } = useSelector((state) => state.notifications);
  const { loggedIn } = useSelector((state) => state.signIn);

  useEffect(() => {
    if (notificationsStatus === "idle" && loggedIn) {
      dispatch(getNotifications());
    }
  }, [notificationsStatus, loggedIn, dispatch]);

  return (
    <Routes>
      <Route
        path="/"
        element={
          <Suspense fallback={<PrePageLoader />}>
            <HomePage />
          </Suspense>
        }
      />
      <Route
        path="/cart"
        element={
          <Suspense fallback={<PrePageLoader />}>
            <OrderPage />
          </Suspense>
        }
      />
      <Route
        path="/login"
        element={
          <Suspense fallback={<PrePageLoader />}>
            <LoginPage />
          </Suspense>
        }
      />
      <Route
        path="/register"
        element={
          <Suspense fallback={<PrePageLoader />}>
            <RegisterPage />
          </Suspense>
        }
      />
      <Route
        path="/forget-password"
        element={
          <Suspense fallback={<PrePageLoader />}>
            <ForgetPassword />
          </Suspense>
        }
      />
      <Route
        path="/profile"
        element={
          <Suspense fallback={<PrePageLoader />}>
            <ProfilePage />
          </Suspense>
        }
      />
      <Route
        path="/restaurant"
        element={
          <Suspense fallback={<PrePageLoader />}>
            <RestaurantPage />
          </Suspense>
        }
      />
      <Route
        path="/our-branches"
        element={
          <Suspense fallback={<PrePageLoader />}>
            <OurBranches />
          </Suspense>
        }
      />
      <Route
        path="/notifications"
        element={
          <Suspense fallback={<PrePageLoader />}>
            <NotificationsPage />
          </Suspense>
        }
      />
      <Route
        path="/my-orders"
        element={
          <Suspense fallback={<PrePageLoader />}>
            {/* <MyOrdersPage /> */}
            <OrdersPage />
          </Suspense>
        }
      />
      <Route
        path="/contact-us"
        element={
          <Suspense fallback={<PrePageLoader />}>
            <SettingsPage routeName="Contact Us" />
          </Suspense>
        }
      />
      <Route
        path="/about-us"
        element={
          <Suspense fallback={<PrePageLoader />}>
            <SettingsPage routeName="About Us" />
          </Suspense>
        }
      />
      <Route
        path="/privacy-policy"
        element={
          <Suspense fallback={<PrePageLoader />}>
            <SettingsPage routeName="Privacy Policy" />
          </Suspense>
        }
      />
      <Route
        path="/terms-conditions"
        element={
          <Suspense fallback={<PrePageLoader />}>
            <TermsConditions />
          </Suspense>
        }
      />
      <Route
        path="/check-otp"
        element={
          <Suspense fallback={<PrePageLoader />}>
            <CheckOtpCodePage />
          </Suspense>
        }
      />
      <Route
        path="/reset-password-by-phone"
        element={
          <Suspense fallback={<PrePageLoader />}>
            <ResetPasswordByPhone />
          </Suspense>
        }
      />
      <Route
        path="/under-construction"
        element={
          <Suspense fallback={<PrePageLoader />}>
            <MyOrdersPage />
          </Suspense>
        }
      />

      <Route path="/my-orders/success/:t_id" element={<OrdersPage />} />
    </Routes>
  );
}

export default SiteRoutes;
