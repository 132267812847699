import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'

const initialState = {
  privacyTermsData: [],
  privacyTermsStatus: 'idle',
  error: null
}

// getPrivacyTerms
export const getPrivacyTerms = createAsyncThunk(
  'privacyTerms/getPrivacyTerms',
  async (params, { getState, rejectWithValue }) => {
    const lang = getState().lang.lang
    try {
      const { data } = await axios.get(`https://backend.pizzaservice.fi/api/getGeneralText/${params?.key}`, {
        headers: {
          'X-localization': lang
        }
      })
      return data.data
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)

const privacyTermsSlice = createSlice({
  name: 'privacyTerms',
  initialState,
  extraReducers: builder => {
    builder
      .addCase(getPrivacyTerms.pending, state => {
        state.status = 'loading'
      })
      .addCase(getPrivacyTerms.fulfilled, (state, action) => {
        state.status = 'succeeded'
        state.privacyTermsData = action.payload
      })
      .addCase(getPrivacyTerms.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.error.message
      })
  }
})

export default privacyTermsSlice.reducer
