import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { applyCoupon } from './orderSlice'

const initialState = {
  couponData: [],
  getCouponStatus: 'idle',
  error: null
}

// getCoupon
export const getCoupon = createAsyncThunk(
  'coupon/getCoupon',
  async ({ cp }, { getState, rejectWithValue, dispatch }) => {
    const lang = getState().lang.lang
    try {
      const { data } = await axios.get(process.env.REACT_APP_API + `getCoupon/${cp}`, {
        headers: {
          'X-localization': lang
        }
      })
      dispatch(applyCoupon(data?.data))
      return data.data
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)

const couponsSlice = createSlice({
  name: 'coupon',
  initialState,
  extraReducers: builder => {
    builder
      .addCase(getCoupon.pending, state => {
        state.getCouponStatus = 'loading'
      })
      .addCase(getCoupon.fulfilled, (state, action) => {
        state.getCouponStatus = 'succeeded'
        state.couponData = action.payload
      })
      .addCase(getCoupon.rejected, (state, action) => {
        state.getCouponStatus = 'failed'
        state.error = action.error.message
      })
  }
})

export default couponsSlice.reducer
